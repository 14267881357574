
const shaders = {
  vert : `
  attribute vec4 aVertexPosition;
  void main() {
    gl_Position = aVertexPosition;
  }
  `,
  frag: `
    precision mediump float;
    #define NUM_OCTAVES 5
    uniform vec2 uResolution;
    uniform float time;
    float rand(float n){return fract(sin(n) * 43758.5453123);}
    float rand(vec2 n) { return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453); }
    float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
    vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
    vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
    float noise(float p){
  	  float fl = floor(p);
      float fc = fract(p);
      return mix(rand(fl), rand(fl + 1.0), fc);
    }
    float noise(vec2 p){
    	vec2 ip = floor(p);
    	vec2 u = fract(p);
    	u = u*u*(3.0-2.0*u);
    	float res = mix(
    		mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
    		mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
    	return res*res;
    }
    float noise(vec3 p){
      vec3 a = floor(p);
      vec3 d = p - a;
      d = d * d * (3.0 - 2.0 * d);
      vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
      vec4 k1 = perm(b.xyxy);
      vec4 k2 = perm(k1.xyxy + b.zzww);
      vec4 c = k2 + a.zzzz;
      vec4 k3 = perm(c);
      vec4 k4 = perm(c + 1.0);
      vec4 o1 = fract(k3 * (1.0 / 41.0));
      vec4 o2 = fract(k4 * (1.0 / 41.0));
      vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
      vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
      return o4.y * d.y + o4.x * (1.0 - d.y);
    }
    float fbm(float x) {
    	float v = 0.0;
    	float a = 0.5;
    	float shift = float(100);
    	for (int i = 0; i < NUM_OCTAVES; ++i) {
    		v += a * noise(x);
    		x = x * 2.0 + shift;
    		a *= 0.5;
    	}
    	return v;
    }
    float fbm(vec2 x) {
    	float v = 0.0;
    	float a = 0.5;
    	vec2 shift = vec2(100);
    	// Rotate to reduce axial bias
        mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
    	for (int i = 0; i < NUM_OCTAVES; ++i) {
    		v += a * noise(x);
    		x = rot * x * 2.0 + shift;
    		a *= 0.5;
    	}
    	return v;
    }
    float fbm(vec3 x) {
    	float v = 0.0;
    	float a = 0.5;
    	vec3 shift = vec3(100);
    	for (int i = 0; i < NUM_OCTAVES; ++i) {
    		v += a * noise(x);
    		x = x * 2.0 + shift;
    		a *= 0.5;
    	}
    	return v;
    }
    void main() {
      vec2 pos = 2.*(gl_FragCoord.xy - 0.5*uResolution.xy) / min(uResolution.x, uResolution.y);
      float x = pos.x;
      float y = pos.y;
      float t = time;
      gl_FragColor =
    `
};

const VERTEX_COUNT = 6;
const VERTEX_COMPS = 2;
let PAGE;
let PCOUNT = 1;
let SKETCH_ID;
let NEXT_ID;
let PREV_ID;

let EDITOR_URL = "https://editor.inverse.website/";
let SERVER_URL = "https://api.inverse.website/";

if(process.env.NODE_ENV !== "production") {
  EDITOR_URL = "http://localhost:1234/";
  SERVER_URL = "http://localhost:3000/";
}

console.log(process.env.POOP);

const $wrapper = document.getElementById('wrapper');
const $next = document.querySelector(".nav__btn-next");
const $prev = document.querySelector(".nav__btn-prev");

function createDOM(data, id) {
  let container = document.getElementById(`container__${id}`);
  let textarea = container.querySelector('.editor__textarea');
  let title = container.querySelector('.editor__title');
  let links = container.querySelector('.editor__links');
  let canvas = container.querySelector('canvas');
  let fork = container.querySelector('.button-fork');

  container.classList += ' visible';
  textarea.innerHTML = data.program.replace(/\n/gi, '<br>').replace(/\s/gi, '&nbsp;');

  title.innerHTML = `${data.title} by`;
  let linkText = '';

  if(data.twitter) {
    linkText = `<a href="https://twitter.com/${data.twitter}" target="_blank">${data.author == "Anonymous" ? "@"+data.twitter : data.author}</a> `;
  } else {
    linkText = `${data.author} `;
  }

  if(data.parent)  {
     linkText += `|| based on <a href="${window.location.pathname}?sketch=${data.parent}" target="_blank">this sketch</a>`;
  }

  links.innerHTML = linkText;

  fork.href = `${EDITOR_URL}?sketch=${data._id}`;

  let clickFn = () => window.location.href = window.location.pathname + `?sketch=${data._id}`;
  if(document.body.classList.contains('single')) {
    container.removeEventListener('click', clickFn);
  } else {
    container.addEventListener('click', clickFn);
  }

  return canvas;
}

function createShader(gl, type, source) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if(success) return shader;
  console.log(gl.getShaderInfoLog(shader));
  gl.deleteShader(shader);
}

function createProgram(gl, vertShader, fragShader) {
  const vs = createShader(gl, gl.VERTEX_SHADER, vertShader);
  const fs = createShader(gl, gl.FRAGMENT_SHADER, fragShader);
  const program = gl.createProgram();
  gl.attachShader(program, vs);
  gl.attachShader(program, fs);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if(success) return program;
  console.log(gl.getProgramInfoLog(program));
  gl.deleteProgram(program);
}

function initCanvas(data, id) {
  const canvas = createDOM(data, id);
  const gl = canvas.getContext("webgl");
  const program = createProgram(gl, shaders.vert, `${shaders.frag}vec4(${data.output},1.0);}`);
  const vertexArray = new Float32Array([
      -1., 1., 1., 1., 1., -1.,
      -1., 1., 1., -1., -1., -1.
  ]);
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, vertexArray, gl.STATIC_DRAW);


  gl.useProgram(program);

  const aVertexPosition = gl.getAttribLocation(program, "aVertexPosition");
  const timeLoc = gl.getUniformLocation(program, "time");
  const resLoc = gl.getUniformLocation(program, "uResolution");

  gl.enableVertexAttribArray(aVertexPosition);
  gl.vertexAttribPointer(aVertexPosition, VERTEX_COMPS, gl.FLOAT, false, 0, 0);

  let animate = function(t) {
    if(canvas.clientWidth !== canvas.width) canvas.width = canvas.clientWidth;
    if(canvas.clientHeight !== canvas.height) canvas.height = canvas.clientHeight;
    gl.viewport(0,0,canvas.width, canvas.height);
    gl.uniform2fv(resLoc, [canvas.width, canvas.height]);

    gl.uniform1f(timeLoc, t/1000);
    gl.clearColor(1., 1., 1.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLES, 0, VERTEX_COUNT);
  }
  return animate;
}

function load(url) {
  fetch(url)
  .then(r=>r.json())
  .then( data => {
    console.log(data);
    PCOUNT = data.pcount;
    NEXT_ID = data.next_id;
    PREV_ID = data.prev_id;
    if(PCOUNT <= 1) {
      $next.classList.add("hidden");
      $prev.classList.add("hidden");
    } else {
      $next.classList.remove("hidden");
      $prev.classList.remove("hidden");
    }
    let anims = data.sketches.map( (d, i) => initCanvas(d, i) );

    let animateAll = function(t) {
      anims.forEach( anim => anim(t) );
      window.requestAnimationFrame(animateAll)
    }

    animateAll(0);
  });
}

function navigate(dir) {
  if(document.body.classList.contains('single')) {
    if(dir==1) {
      window.location.href = window.location.pathname + `?sketch=${NEXT_ID}`;
    } else {
      window.location.href = window.location.pathname + `?sketch=${PREV_ID}`;
    }
  } else {
    PAGE += dir;
    if(PAGE <= 0) { PAGE = PCOUNT;}
    else if(PAGE > PCOUNT){ PAGE = PAGE % PCOUNT}
    window.location.href = window.location.pathname + `?page=${PAGE}`;
  }
}

function init() {
  let params = (new URL(document.location)).searchParams;
  let url = `${SERVER_URL}sketches/`;
  let s = params.get('sketch');
  let p = params.get('page');
  PAGE = parseInt(p) || 1;
  SKETCH_ID = s;
  if(s) {
    url += s;
    document.body.classList.add('single');
  } else if(p) {
    url += `page/${p}`;
    document.body.classList.remove('single');
  }
  load(url);
}


$next.addEventListener('click', ()=>navigate(1) );
$prev.addEventListener('click', ()=>navigate(-1) );

init();
